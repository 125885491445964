import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Your Firebase SDK configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyCbemnHRiKADzXa37l8bcsiIPVc8cwrQRg",
//   authDomain: "ag-frontend-6bdfa.firebaseapp.com",
//   projectId: "ag-frontend-6bdfa",
//   storageBucket: "ag-frontend-6bdfa.appspot.com",
//   messagingSenderId: "479899022764",
//   appId: "1:479899022764:web:81929f66071c7eca677609",
//   measurementId: "G-H1X1E5K3D8"
// };

// // Initialize Firebase
// firebase.initializeApp(firebaseConfig);
