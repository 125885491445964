// src/Dashboard.js

import React, { useState } from 'react';
import FriendsList from './FriendsList';
import DateRangePicker from './DateRangePicker';
import CalendarDisplay from './CalendarDisplay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCalendar, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import './Dashboard.css'; // Import custom styles for Dashboard

const Dashboard = () => {
  // State for managing selected date range
  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });

  // Function to update state with selected date range from DateRangePicker
  const handleDateRangeSelect = (range) => {
    setDateRange(range);
  };

  return (
    <div className="dashboard">
      <div className="combined-section">
        <h2><FontAwesomeIcon icon={faCalendar} /> Select Date Range</h2>
        {/* Pass handleDateRangeSelect to DateRangePicker */}
        <DateRangePicker onDateRangeSelect={handleDateRangeSelect} />
        <h2><FontAwesomeIcon icon={faCalendarAlt} /> Calendar</h2>
        {/* Pass selected date range to CalendarDisplay */}
        <CalendarDisplay dateRange={dateRange} />
      </div>
    </div>
  );
};

export default Dashboard;