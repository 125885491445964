// src/DateRangePicker.js

import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { addDays } from 'date-fns'; // Import addDays function from date-fns

const DateRangePicker = ({ onDateRangeSelect }) => {
  const [startDate, setStartDate] = React.useState(null);

  const handleStartDateChange = (date) => {
    const endDate = addDays(date, 6); // Automatically set end date to 6 days after start date
    setStartDate(date);
    onDateRangeSelect({ startDate: date, endDate }); // Pass the selected range up
  };

  return (
    <div className="date-range-picker">
      {/* Moved the explanatory text into a <p> tag for better styling control */}
      <p className="date-explanation">Select Start Date (7-day range will be automatically selected)</p>
      <DatePicker
        selected={startDate}
        onChange={handleStartDateChange}
        placeholderText="Start Date"
        className="date-picker-input" // Added for additional styling control
      />
    </div>
  );
};

export default DateRangePicker;