// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import Welcome from './Welcome';
import Dashboard from './Dashboard';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Ubuntu+Mono:wght@400;700&display=swap');

  body {
    font-family: 'Ubuntu Mono', monospace;
  }
`;

function App() {
  return (
    <Router>
      <GlobalStyle />
      <div className="App">
        <Routes>
          <Route path="/" element={<Welcome />} />
          <Route path="/dashboard" element={<Dashboard />} />
          {/* Route for Dashboard component */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;