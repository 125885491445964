import React, { useRef, useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { format, parseISO } from 'date-fns';
import './CalendarDisplay.css';

const CalendarDisplay = ({ dateRange }) => {
  const calendarRef = useRef(null);
  const [events, setEvents] = useState([]);
  const [magicalEvents, setMagicalEvents] = useState([]);

  const fetchMagicalEvents = () => {
    fetch('/api/latest-events/?format=json')
      .then(response => response.json())
      .then(data => {
        const parsedEvents = data.map(event => ({
          ...event,
          url: event.link // Store the link as `url` for FullCalendar to handle it as clickable
        }));
        setMagicalEvents(parsedEvents);
      })
      .catch(error => console.error('Error fetching magical events:', error));
  };

  const loadEvents = (start, end) => {
    if (!start || !end) return;
    const eventsUrl = `/api/calendar/events/?start_date=${format(start, 'MM-dd-yyyy')}&end_date=${format(end, 'MM-dd-yyyy')}`;
    
    fetch(eventsUrl)
      .then(response => response.json())
      .then(data => {
        setEvents(data);
      })
      .catch(error => console.error('Error fetching events:', error));
  };

  useEffect(() => {
    fetchMagicalEvents(); // Fetch magical events once on component mount

    if (dateRange.startDate) {
      const startDate = typeof dateRange.startDate === 'string' ? parseISO(dateRange.startDate) : dateRange.startDate;
      const calendarApi = calendarRef.current?.getApi();

      if (calendarApi) {
        calendarApi.gotoDate(startDate);

        const start = startDate;
        const end = new Date(startDate);
        end.setMonth(start.getMonth() + 1);

        loadEvents(start, end);
      }
    }
  }, [dateRange.startDate]);

  const renderEventContent = (eventInfo) => {
    const isMagicalEvent = magicalEvents.some(magicalEvent => eventInfo.event.title === magicalEvent.title);
    const className = isMagicalEvent ? 'event-gradient' : 'event-normal';
    return (
      <div className={className}>
        <b>{eventInfo.event.title}</b>
        <p></p>
        <i>{eventInfo.timeText}</i>
      </div>
    );
  };

  return (
    <div className="calendar-display">
      <FullCalendar
        ref={calendarRef}
        plugins={[timeGridPlugin, interactionPlugin]}
        initialView="timeGridWeek"
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'timeGridWeek,timeGridDay'
        }}
        events={[...events, ...magicalEvents]}
        eventContent={renderEventContent}
        timeZone='UTC'
        eventClick={(clickInfo) => {
          clickInfo.jsEvent.preventDefault(); // Prevent the default link navigation
          if (clickInfo.event.url) {
            window.open(clickInfo.event.url, '_blank');
          }
        }}
        datesSet={({ start, end }) => {
          loadEvents(start, end);
        }}
      />
    </div>
  );
};

export default CalendarDisplay;
