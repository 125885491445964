import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import styled, { keyframes } from 'styled-components';
import './Welcome.css';
import aniImage from './images/Ani_bitmoji.png';
import rahulImage from './images/Rahul_bitmoji.png';
import santiImage from './images/Santi_bitmoji.png';
import xenaImage from './images/Xena_bitmoji.png';
import alImage from './images/Al_bitmoji.png';
import vishImage from './images/Vish_bitmoji.png';
import Community_bg from './images/Community_Magical.png';

const Welcome = () => {
  const [selectedFriends, setSelectedFriends] = useState([]);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  useEffect(() => {
    const fetchUser = async () => {
      const response = await fetch('/api/user-info/');
      const data = await response.json();
      setFirstName(data.first_name);
      setLastName(data.last_name);
    };

    fetchUser();
  }, []);

  const toggleFriendSelection = (name) => {
    setSelectedFriends(prev => {
      if (prev.includes(name)) {
        return prev.filter(friendName => friendName !== name);
      }
      return [...prev, name];
    });
  };

  const friends = [
    { name: 'Rahul', image: rahulImage },
    { name: 'Ani', image: aniImage },
    { name: 'Vichu', image: vishImage },
    { name: 'Santi', image: santiImage },
    { name: 'Xena', image: xenaImage },
    { name: 'Big Al', image: alImage },
  ];

  return (
    <PageContainer>
      <Header>
        <FontAwesomeIcon icon={faCalendarAlt} />
        <HeaderText>Welcome to Magi Cal, {firstName}.</HeaderText>
      </Header>
      <SearchBar>
        <SearchIcon>🔍</SearchIcon>
        <SearchInput placeholder="Who do you want to hang with?..." />
      </SearchBar>
      <CommunityText>
        <FontAwesomeIcon icon={faCalendarAlt} />
        <CommunityTextContent>Your Community</CommunityTextContent>
      </CommunityText>
      <FriendCirclesContainer>
        {friends.map((friend) => (
          <FriendSquare 
            key={friend.name}
            selected={selectedFriends.includes(friend.name)}
            onClick={() => toggleFriendSelection(friend.name)}
          >
            <FriendImageContainer>
              {friend.image ? (
                <FriendImage src={friend.image} alt={friend.name} />
              ) : (
                <FriendEmoji>{friend.emoji}</FriendEmoji>
              )}
            </FriendImageContainer>
            <FriendName>{friend.name}</FriendName>
            <Checkbox 
              type="checkbox" 
              checked={selectedFriends.includes(friend.name)} 
              onChange={() => toggleFriendSelection(friend.name)}
            />
          </FriendSquare>
        ))}
      </FriendCirclesContainer>
      <Link to="/dashboard">
        <HangButton>Let's Hang!</HangButton>
      </Link>
    </PageContainer>
  );
};

const gradientBG = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;


const PageContainer = styled.div`
  position: relative; // To establish a positioning context for pseudo-elements
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
  overflow-y: auto;

  @media (max-width: 768px) {
    padding: 16px;
  }

  // // Image background covering the defined height
  // background: url(${Community_bg}) no-repeat center center / cover; 

  // Gradient background covering entire element via pseudo-element
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    z-index: -1; // Ensures it stays in the background
    background-size: 400% 400%;
    animation: ${gradientBG} 15s ease infinite;
  }
`;

const Header = styled.div`
  color: white;
  margin-bottom: 48px;
  display: flex;
  align-items: center;
  font-family: 'Roboto', sans-serif;
`;

const HeaderText = styled.h1`
  margin-left: 12px;
  font-size: 36px; // Increased size for better visibility
  color: #000000; // Ensures the text is black

  @media (max-width: 768px) {
    font-size: 28px; // Adjust size for smaller screens
  }
`;

const SearchBar = styled.div`
  background-color: white;
  border-radius: 30px;
  padding: 8px 16px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  width: 400px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    width: 300px;
  }
`;

const SearchIcon = styled.span`
  margin-right: 8px;
  color: #3b5998;
`;

const SearchInput = styled.input`
  border: none;
  outline: none;
  font-size: 16px;
  width: 100%;
  font-family: 'Roboto', sans-serif;

  &::placeholder {
    color: #aaa;
  }
`;

const CommunityText = styled.div`
  color: white;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  font-family: 'Roboto', sans-serif;
`;

const CommunityTextContent = styled.h2`
  margin-left: 12px;
  font-size: 36px; // Matching HeaderText size for consistency
  color: #000000; // Changed color to black for better visibility

  @media (max-width: 768px) {
    font-size: 28px; // Consistent adjustment for smaller screens
  }
`;

const FriendCirclesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 32px;
  justify-items: center;
  margin-bottom: 48px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

const FriendSquare = styled.div`
  width: 120px;
  height: 150px;
  background-color: ${({ selected }) => selected ? '#bada55' : 'white'}; // Bright green when selected
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 15px; /* Rounded edges */
  cursor: pointer;
  margin-bottom: 12px;
`;

const Checkbox = styled.input`
  margin-top: 10px;
`;

const FriendImageContainer = styled.div`
  width: 100px;
  height: 100px;
  overflow: hidden;
  position: relative;
  border-radius: 15px; /* Rounded edges for images as well */
`;

const FriendEmoji = styled.span`
  font-size: 48px;
`;

const FriendName = styled.span`
  font-size: 14px;
  text-align: center;
  font-family: 'Ubuntu Mono', monospace; /* Changed font to Ubuntu Mono and made it bold */
  font-weight: bold;
  margin-top: 8px;
`;

const FriendImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  position:absolute;
  top: 0;
  left: 0;
`;

const HangButton = styled.button`
  background-color: #ff4d4d;
  color: white;
  border: none;
  border-radius: 30px;
  padding: 16px 32px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    background-color: #e74c3c;
    transform: translateY(-2px);
  }
`;

export default Welcome;
